/* eslint-disable security/detect-object-injection */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toCSV } from 'react-csv/lib/core';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, DatePicker, Radio, Select, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { getCountriesGql, getCurrenciesGql, getMetricsGql, getOperatorsGql, getSlotsGql } from '../../graphql';
import { TBalance, TBalanceCsv, TOperator, TSlot } from '../../types';
import MainFilter from '../MainFilter/MainFilter';
import mainFilterStyle from '../MainFilter/style.module.scss';
import TesterSelector from '../MainFilter/TesterSelector';
import styles from './styles.module.scss';
import useTable from './useTable';
import useTimezoneRangepicker from './useTimezoneRangepicker';

export interface FilterParams {
  to: string;
  from: string;
  slots: FilterItem[];
  clients: FilterItem[];
  operators: FilterItem[];
  currencies: FilterItem[];
  countries: FilterItem[];
  clientCurrencies: FilterItem[];
  clientCountries: FilterItem[];
}

interface FilterParamsGql {
  to: string;
  from: string;
  slots: string[];
  clients?: string[];
  operators: string[];
  currencies: string[];
  countries: string[];
  clientCurrencies: string[];
  clientCountries: string[];
  testers?: boolean;
}

enum EGroupBy {
  NONE = 'NONE',
  CLIENTS = 'operatorId',
  OPERATORS = 'clientId',
  GAMES = 'slotId',
  CURRENCIES = 'currency',
}

export const getFormattedMoney = (value: number, showCurrency = true): string => {
  if (typeof value !== 'number') return '---';
  return new Intl.NumberFormat('en-US', {
    style: showCurrency ? 'currency' : 'decimal',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export type TKeyFilterParams = keyof Omit<FilterParams, 'to' | 'from'>;

export interface FilterItem {
  name: string;
  slug: string;
}

const Ggr = () => {
  const { t, i18n } = useTranslation();
  const sortRef = useRef({
    sorter: (_a: TBalance, _b: TBalance) => 0 as number,
    order: '',
  });
  const { exportHeaders, columns, formatTableData } = useTable();

  const { dateFrom, dateTo, handleTimezoneChange, filterParams, setFilterParams, datepickerChangeHandler, timezone } =
    useTimezoneRangepicker();

  const [showCountryFilterItem] = useState(true);

  const handleTableChange = (_pagination: unknown, _filters: unknown, _sorter: unknown) => {
    setSelectedRowKeys([]);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [normalizeToEur, setNormalizeToEur] = useState(true);
  const [groupBy, setGroupBy] = useState(EGroupBy.NONE);
  const [allData, setAllData] = useState<TBalance[]>([]);
  const [applyButtonClicked, setApplyButtonClicked] = useState(false);
  const [tableData, setTableData] = useState<TBalanceCsv[]>([]);
  const [tableDataToShow, setTableDataToShow] = useState<TBalanceCsv[]>([]);
  const [summary, setSummary] = useState<TBalanceCsv>({} as TBalanceCsv);
  const [testersVisible, setTestersVisible] = useState<boolean | undefined>(false);

  const updateTableData = () => {
    if (metricsData?.billingTotalStatistic.length) {
      const { data, summary } = formatTableData(
        metricsData?.billingTotalStatistic,
        operatorsData?.operators!,
        slotsData?.slots!,
      );
      setTableData(data);
      setSummary(summary);
    } else {
      setTableData([]);
      setAllData([]);
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const clientCurrenciesArray = ['Asia-PronetGaming', 'Asia-iGP', 'Asia-P7'];
  const clientCountriesArray = ['Asia-Anakatech', 'Asia-BetConstruct', 'Asia-Hub88'];
  const [getBilling, { data: metricsData, loading: metricsLoading }] = useLazyQuery<
    { billingTotalStatistic: TBalance[] },
    { filter: FilterParamsGql }
  >(getMetricsGql);
  const [getSlots, { data: slotsData, previousData: previousSlotsData }] = useLazyQuery<
    { slots: TSlot[] },
    { take?: number; skip?: number; operators?: string[]; clients?: string[] }
  >(getSlotsGql);
  const { data: operatorsData } = useQuery<{ operators: TOperator[] }>(getOperatorsGql);
  const { data: currencyData } = useQuery<{ currencies: string[] }>(getCurrenciesGql);
  const { data: countriesKeys } = useQuery<{ countries: string[] }>(getCountriesGql);

  const countries = useMemo(() => {
    try {
      const intl = new Intl.DisplayNames([i18n.language], { type: 'region' });
      return countriesKeys?.countries
        .map((key) => {
          return {
            slug: key,
            name: intl.of(key) || key,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    } catch (e) {
      return countriesKeys?.countries
        .map((key) => {
          return {
            slug: key,
            name: key,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }, [countriesKeys, i18n.language]);

  useEffect(() => {
    if (metricsData && !metricsLoading && slotsData) {
      updateTableData();
    }
  }, [metricsData, metricsLoading, slotsData]);
  const slots = useMemo(
    () =>
      (slotsData?.slots || previousSlotsData?.slots)
        ?.map((item) => ({ name: item.name, slug: item.id }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [slotsData?.slots, previousSlotsData?.slots],
  );
  const operators = useMemo(
    () =>
      operatorsData?.operators
        .map((item) => ({ name: item.name, slug: item.id }))
        .sort((a, b) => a.name.localeCompare(b.name)) as FilterItem[],
    [operatorsData?.operators],
  );

  const clients = useMemo(
    () =>
      operatorsData?.operators
        .filter((operator) => filterParams.operators.find((operatorFilter) => operatorFilter.slug === operator.id))
        .reduce((acc, operator) => acc.concat(operator.clients), [] as { id: string; name: string }[])
        .map((item) => ({ name: item.name, slug: item.id }))
        .filter((element, index, array) => index === array.findIndex((findElem) => findElem.slug === element.slug))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [operatorsData?.operators, filterParams.operators],
  );
  const currencies = useMemo(
    () =>
      currencyData?.currencies.map((item) => ({ name: item, slug: item })).sort((a, b) => a.name.localeCompare(b.name)),
    [currencyData?.currencies],
  );
  const clientCurrencies = useMemo(
    () =>
      clientCurrenciesArray.map((item) => ({ name: item, slug: item })).sort((a, b) => a.name.localeCompare(b.name)),
    [clientCurrenciesArray],
  );
  const clientCountries = useMemo(
    () => clientCountriesArray.map((item) => ({ name: item, slug: item })).sort((a, b) => a.name.localeCompare(b.name)),
    [clientCountriesArray],
  );

  const onSelectAllChange = (selected: boolean) => {
    if (selected) {
      const keys = allData.map((row) => row.clientId);
      setSelectedRowKeys(keys);
    } else {
      setSelectedRowKeys([]);
    }
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
    onSelectAll: onSelectAllChange,
    hideSelectAll: true,
  };

  const handleCheckboxChange = React.useCallback((filter: TKeyFilterParams, updatedFilters: FilterItem[]) => {
    setFilterParams((prev) => {
      return {
        ...prev,
        [filter]: updatedFilters,
      };
    });
  }, []);

  const handleRadioChange = (options: FilterItem[]) => {
    setFilterParams({
      ...filterParams,
      countries: options,
    });
  };

  useEffect(() => {
    if (metricsData && metricsData.billingTotalStatistic) {
      setAllData(metricsData.billingTotalStatistic);
    }
    getSlots({
      variables: {
        operators: filterParams.operators.map((i) => i.slug),
      },
    });
  }, []);

  useEffect(() => {
    if (!normalizeToEur || groupBy === EGroupBy.NONE) {
      setTableDataToShow(tableData);
      return;
    }

    const groups: Record<string, TBalanceCsv> = {};
    tableData.forEach((item) => {
      const name = item[groupBy];
      if (!groups[name]) {
        groups[name] = {
          ...item,
          currency: '',
          clientId: '',
          operatorId: '',
          slotId: '',
          totalCredit: 0,
          totalDebit: 0,
          totalRefund: 0,
          totalBuyBet: 0,
          ggr: 0,
        };
        groups[name][groupBy] = name;
        return;
      }
      const group = groups[name];
      group.totalSpin += item.totalSpin;
      group.totalBuyCount += item.totalBuyCount;
      group.totalCreditEur += item.totalCreditEur;
      group.totalDebitEur += item.totalDebitEur;
      group.totalRefundEur += item.totalRefundEur;
      group.totalBuyBetEur += item.totalBuyBetEur;
      group.ggrEur += item.ggrEur;
    });
    Object.values(groups).forEach((group) => {
      group.totalCreditEur = Number(group.totalCreditEur?.toFixed(2));
      group.totalDebitEur = Number(group.totalDebitEur?.toFixed(2));
      group.totalRefundEur = Number(group.totalRefundEur?.toFixed(2));
      group.totalBuyBetEur = Number(group.totalBuyBetEur?.toFixed(2));
      group.ggrEur = Number(group.ggrEur?.toFixed(2));
    });

    setTableDataToShow(Object.values(groups));
  }, [tableData, normalizeToEur, groupBy]);

  const processFilterParams = (
    filterParams: FilterParams | { [s: string]: string | FilterItem[] } | ArrayLike<string | FilterItem[]>,
  ) => {
    const data: [k: string, string | FilterItem[]][] = Object.entries(filterParams);
    const filter = data.reduce((acc, [k, v]) => {
      if (k.includes('clientC')) {
        return acc;
      }
      if (Array.isArray(v)) {
        return {
          ...acc,
          [k]: v.map((i) => i.slug),
        };
      }
      return {
        ...acc,
        [k]: v,
      };
    }, {} as FilterParamsGql);
    filter.testers = testersVisible;
    filter.operators = filter.clients!;
    delete filter.clients;
    return filter;
  };

  const handleFilterParams = (filterParams: FilterParams) => {
    getSlots({
      variables: {
        operators: [...filterParams.operators.map((i) => i.slug), ...filterParams.clients.map((i) => i.slug)],
      },
    });
    getBilling({
      variables: {
        filter: processFilterParams(filterParams),
      },
    });
  };

  const generateCSV = () => {
    const dataToSave = [...tableDataToShow];
    if (sortRef.current.order) {
      dataToSave.sort(sortRef.current.sorter);
      if (sortRef.current.order === 'descend') {
        dataToSave.reverse();
      }
    }

    const csv = toCSV(dataToSave, exportHeaders(t));
    const type = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'application/csv' : 'text/csv';
    const blob = new Blob([csv], { type });
    const filename = `${t('balanceSummary')} ${t('from')} ${filterParams.from} ${t('to')} ${filterParams.to}.csv`;
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.main_container}>
      <h1 className="heder_title">{t('menuGgr')}</h1>
      <div className="table-top-space">
        <MainFilter
          slots={slots || []}
          operators={operators || []}
          clients={clients || []}
          currency={currencies || []}
          clientCurrency={clientCurrencies || []}
          country={countries || []}
          clientCountry={clientCountries || []}
          selectedSlots={filterParams.slots}
          selectedOperators={filterParams.operators}
          selectedClients={filterParams.clients}
          selectedCurrency={filterParams.currencies}
          selectedClientCurrency={filterParams.clientCurrencies || []}
          selectedClientCountry={filterParams.clientCountries || []}
          selectedCountry={filterParams.countries}
          showCountryFilterItem={showCountryFilterItem}
          onCheckboxChange={(filter: string, updatedFilters: FilterItem[]) =>
            handleCheckboxChange(filter as TKeyFilterParams, updatedFilters)
          }
          onChangeBalanceSummary={handleRadioChange}
          applyButtonClicked={applyButtonClicked}
          testersSelector={<TesterSelector value={testersVisible} setValue={setTestersVisible} />}
        >
          <div className={`${mainFilterStyle['main-filter__col']}  ${styles.timezonePickerContainer}`}>
            <div className={mainFilterStyle.title}>{t('fromTo')}</div>
            <Select showSearch value={timezone} onChange={handleTimezoneChange} className={styles.timezoneSelect}>
              {Intl.supportedValuesOf('timeZone').map((timezone) => (
                <Select.Option key={timezone} value={timezone}>
                  {`${dayjs().tz(timezone).format('Z')} ${timezone} `}
                </Select.Option>
              ))}
            </Select>
            <div className={`${styles.timeRangeWrapper}`}>
              <DatePicker.RangePicker
                format={'YYYY-MM-DD'}
                className={styles.calendar}
                defaultValue={[dayjs(dateFrom), dayjs(dateTo)]}
                onChange={datepickerChangeHandler}
              />
            </div>
            <div className={`${styles.buttons}`}>
              <Radio.Group onChange={(evt) => setNormalizeToEur(evt.target.value)} value={normalizeToEur}>
                <Space direction="vertical">
                  <Radio value={true}>{t('normalizeToEur')}</Radio>
                  <Radio.Group
                    disabled={!normalizeToEur}
                    value={groupBy}
                    onChange={(evt) => setGroupBy(evt.target.value)}
                  >
                    <Space direction="vertical" style={{ paddingLeft: 20 }}>
                      <Radio value={EGroupBy.NONE}>{t('dontGroup')}</Radio>
                      <Radio value={EGroupBy.CLIENTS}>{t('groupByClients')}</Radio>
                      <Radio value={EGroupBy.OPERATORS}>{t('groupByOperators')}</Radio>
                      <Radio value={EGroupBy.GAMES}>{t('groupByGames')}</Radio>
                      <Radio value={EGroupBy.CURRENCIES}>{t('groupByCurrencies')}</Radio>
                    </Space>
                  </Radio.Group>

                  <Radio value={false}>{t('originalCurrencies')}</Radio>
                </Space>
              </Radio.Group>
              <Button
                size="large"
                block
                className={`${styles.bigBtn}`}
                onClick={() => {
                  handleTableChange(undefined, undefined, undefined);
                  handleFilterParams(filterParams);
                  setApplyButtonClicked(true);
                  updateTableData();
                  setCurrentPage(1);
                }}
              >
                {t('apply')}
              </Button>
              {metricsData &&
                metricsData.billingTotalStatistic.length > 0 &&
                filterParams.operators.length > 0 &&
                filterParams.slots.length > 0 &&
                filterParams.currencies.length > 0 &&
                filterParams.countries.length > 0 &&
                tableData &&
                tableData.length > 0 && (
                  <>
                    <Button
                      className={styles.bigBtn}
                      block
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={generateCSV}
                    >
                      {t('generate')}
                    </Button>
                  </>
                )}
            </div>
          </div>
        </MainFilter>
      </div>
      <Table
        onChange={(_, __, sort) => {
          if (Array.isArray(sort)) {
            sort = sort[0];
          }
          sortRef.current.sorter = typeof sort.column?.sorter === 'function' ? sort.column?.sorter : () => 0;
          sortRef.current.order = sort.order || '';
        }}
        rowKey="id"
        loading={metricsLoading}
        columns={columns(t, normalizeToEur)}
        dataSource={tableDataToShow}
        size="small"
        pagination={{
          current: currentPage,
          position: ['bottomCenter'],
          showLessItems: true,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: (page) => setCurrentPage(page),
        }}
        rowSelection={rowSelection}
        locale={{ emptyText: t('emptyTable') }}
        summary={() =>
          tableData.length > 0 &&
          normalizeToEur && (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.tableSummary}>
                <Table.Summary.Cell index={0}>{t('summary')}</Table.Summary.Cell>
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell align="right" index={4}>
                  {getFormattedMoney(summary.totalDebitEur, true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={5}>
                  {getFormattedMoney(summary.totalCreditEur, true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={6}>
                  {getFormattedMoney(summary.totalRefundEur, true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={7}>
                  {summary.totalSpin}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={8}>
                  {getFormattedMoney(summary.totalBuyBetEur, true)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center" index={9}>
                  {summary.totalBuyCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={10}>
                  <span style={{ color: summary.ggrEur >= 0 ? 'green' : 'red' }}>
                    {getFormattedMoney(summary.ggrEur, true)}
                  </span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
        }
      />
    </div>
  );
};

export default Ggr;
